import axios from 'axios';
import { splitName, toName } from '@/utility/PersonalName';
import { SaveResultItem } from '@/utility/save-result';
import { isValidMail, isValidTel } from '@/utility/valid';
import { isValidPassword } from '@/utility/spassword';
import { toDateKey } from '@/utility/date';
import { SFamilyItem, SFamily } from './AddSiblingDialog';

export const nameLength = 26; //< 漢字人名(保護者共通) 最大長
export const nameALength = 10; //< 姓文字列長
export const nameBLength = 15; //< 名文字列長
export const telLength = 20;
export const mailLength = 60;
export const memoLength = 32;
export const passwordLength = 16;
export const sPwMinLength = 8; //< パスワード最小
export const sPwMaxLength = 60; //< パスワード最大
export const custNoLength = 15;

export const minDate = '1900-01-01';
export const maxDate = '2099-12-31';

export interface ParentItem {
  pName: string;
  pKana: string;
  pTel: string;
  pMail: string;
  pPassword: string;
  pMemo: string;
  custNo: string;
}

export interface ParentEditItem {
  pcode: number;
  pstdate: string;
  peddate: string;
  pname: string;
  pkana: string;
  pmail: string;
  ptel: string;
  ppassword: string;
  hpassword: string;
  pmemo: string;
  custno: string;
  pkStDates: string[]; //< 取得時のみ利用
}

export interface Parent {
  pCode: number;
  pStDate: string;
  pEdDate: string;
  pNameA: string;
  pNameB: string;
  pKanaA: string;
  pKanaB: string;
  pMail: string;
  pTel: string;
  pPassword: string;
  hPassword: string;
  pMemo: string;
  custNo: string;
  pkStDate: string; //< ロード時のpStDate
  pkStDates: Date[]; //< 全pStDate(Key)
}

export function initParent(): Parent {
  const today = new Date();
  const beginOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  return {
    pCode: 0,
    pStDate: toDateKey(beginOfMonth),
    pEdDate: '',
    pNameA: '',
    pNameB: '',
    pKanaA: '',
    pKanaB: '',
    pMail: '',
    pTel: '',
    pPassword: '',
    hPassword: '',
    pMemo: '',
    custNo: '',
    pkStDate: '',
    pkStDates: [],
  };
}

export async function isExistRecord(pCode: number, stDate: string): Promise<boolean> {
  const strDate = toDateKey(stDate.dateTextToDate());
  const res = await axios.get<boolean>(`/api/MasterParent/isExist/${pCode}`, {
    params: {
      pkstdate: strDate,
    },
  });
  return res.data;
}

export async function loadParentByCcode(cCode: string, pkstdate?: string): Promise<Parent> {
  const params = pkstdate ? { params: { pkstdate: pkstdate } } : {};

  const parent = (await axios.get<ParentEditItem>('/api/MasterParent/' + cCode, params)).data;
  return Promise.resolve(toParent(parent));
}

export async function loadParentByPcode(pCode: string, pkstdate?: string): Promise<Parent> {
  const params = pkstdate ? { params: { pkstdate: pkstdate } } : {};

  const parent = (await axios.get<ParentEditItem>('/api/MasterParent/pcode/' + pCode, params)).data;
  return Promise.resolve(toParent(parent));
}

function toParent(item: ParentEditItem): Parent {
  const [pNameA, pNameB] = splitName(item.pname);
  const [pKanaA, pKanaB] = splitName(item.pkana);
  const parent: Parent = {
    pCode: item.pcode,
    pStDate: item.pstdate,
    pEdDate: item.peddate,
    pNameA: pNameA,
    pNameB: pNameB,
    pKanaA: pKanaA,
    pKanaB: pKanaB,
    pMail: item.pmail,
    pTel: item.ptel,
    pPassword: item.ppassword,
    hPassword: item.hpassword,
    pMemo: item.pmemo,
    custNo: item.custno,
    pkStDate: item.pstdate,
    pkStDates: item.pkStDates.map(v => v.dateTextToDate()),
  };
  return parent;
}

export async function addParent(cCode: number, parent: Parent): Promise<SaveResultItem> {
  // 仮データ
  return new Promise(resolve => {
    setTimeout(() => {
      resolve({
        saved: true,
        message: '保護者の追加が成功しました。（仮データ）',
      });
    }, 500);
  });
}

export async function updateParent(pCode: number, parent: Parent): Promise<SaveResultItem> {
  const response = await axios.put<SaveResultItem>('/api/MasterParent/' + pCode, toEditItem(parent), {
    params: {
      pkStDate: parent.pkStDate, //< 編集画面のロード時の値(pk)を渡す yyyy-MM-dd形式
    },
  });
  return response.data;
}

export async function isExistPmail(pmail: string): Promise<boolean> {
  const response = await axios.get<boolean>('/api/MasterParent/isexistpmail/' + pmail);
  return response.data;
}

function toEditItem(parent: Parent): ParentEditItem {
  const item: ParentEditItem = {
    pcode: parent.pCode,
    pstdate: parent.pStDate,
    peddate: parent.pEdDate,
    pname: toName(parent.pNameA, parent.pNameB),
    pkana: toName(parent.pKanaA, parent.pKanaB),
    pmail: parent.pMail.trim(),
    ptel: parent.pTel.trim(),
    ppassword: parent.pPassword,
    hpassword: parent.hPassword,
    pmemo: parent.pMemo?.trim(),
    custno: parent.custNo?.trim(),
    pkStDates: [],
  };
  return item;
}

export interface AddParentCtrl {
  disp: boolean;
  parent: Parent;
  parentJson: string;
  saved: boolean;
  selectedParent: boolean;
  families: SFamily[];
}

export function initAddParentCtrl(): AddParentCtrl {
  const parent = initParent();
  const ctrl = {
    disp: false,
    parent: parent,
    parentJson: JSON.stringify(parent),
    saved: false,
    selectedParent: false,
    families: [],
  };
  return ctrl;
}

export function okAddParentDialog(ctrl: AddParentCtrl): AddParentCtrl {
  ctrl.disp = false;
  return ctrl;
}

//  親追加時
export async function openAddParentDialog(ctrl: AddParentCtrl, parent: Parent, selected: boolean): Promise<void> {
  ctrl.saved = false;
  ctrl.selectedParent = selected;
  ctrl.disp = true;
  if (!parent.pCode) {
    axios.get<number>('/api/MasterParent/maxpcode').then(response => {
      ctrl.parent = { ...parent }; //< NG 内部に配列を持つ
      ctrl.parent.pCode = response.data + 1;
      ctrl.parentJson = JSON.stringify(ctrl.parent);
    });
  }
}

//  親編集時
export async function openEditParentDialog(ctrl: AddParentCtrl, parent: Parent, selected: boolean): Promise<void> {
  ctrl.parent = { ...parent }; //< NG 内部に配列を持つ
  ctrl.parentJson = JSON.stringify(ctrl.parent);
  ctrl.saved = false;
  ctrl.selectedParent = selected;
  ctrl.disp = true;
}

export async function closeParentDialog(ctrl: AddParentCtrl) {
  ctrl.disp = false;
}

export function cancelAddParentDialog(ctrl: AddParentCtrl) {
  ctrl.disp = false;
}

export async function getMaxPCode(parent: Parent) {
  axios.get<number>('/api/MasterParent/maxpcode').then(response => {
    parent.pCode = response.data + 1;
  });
}

export const askEditMsg = '編集デーが保存されていません。保存せず移動しますか？';
export const askCreate = '新たに履歴データが作成されようとしています。保存しますか？';

export function checkPNameA(parent: Parent): boolean {
  return isValidNameA(parent.pNameA);
}

export function checkPNameB(parent: Parent): boolean {
  return isValidNameB(parent.pNameB);
}

export function checkPKanaA(parent: Parent): boolean {
  return isValidKanaA(parent.pKanaA);
}

export function checkPKanaB(parent: Parent): boolean {
  return isValidKanaB(parent.pKanaB);
}

export function checkPMail(parent: Parent): boolean {
  return !!parent.pMail && isValidPMail(parent.pMail);
}

export function checkPTel(parent: Parent): boolean {
  return !parent.pTel || isValidPTel(parent.pTel);
}

export function checkPMemo(parent: Parent): boolean {
  return isValidPMemo(parent.pMemo);
}

export function checkCustNo(parent: Parent): boolean {
  return isValidCustNo(parent.custNo);
}

export function checkStDate(parent: Parent): boolean {
  if (!parent.pStDate) return false;
  return minDate <= parent.pStDate && parent.pStDate < maxDate;
}

export function isValidKanaA(kana: string): boolean {
  const str = kana.trim().replaceAll('　', ' ');
  const res = str.match(/^[ぁ-んー\s]*$/);
  return res !== null && str.indexOf(' ') < 0 && str.length > 0 && str.length <= nameALength;
}

export function isValidKanaB(kana: string): boolean {
  const str = kana.trim().replaceAll('　', ' ');
  const res = str.match(/^[ぁ-んー\s]*$/);
  return res !== null && str.length > 0 && str.length <= nameBLength;
}

function isValidNameA(nameA: string): boolean {
  const str = nameA.trim().replaceAll('　', ' ');
  return str.indexOf(' ') < 0 && str.length > 0 && str.length <= nameALength;
}

function isValidNameB(nameB: string): boolean {
  const str = nameB.trim().replaceAll('　', ' ');
  return str.length > 0 && str.length <= nameBLength;
}

export function isValidPMail(pMail: string): boolean {
  return isValidMail(pMail, mailLength);
}

export function isValidPTel(pTel: string): boolean {
  return isValidTel(pTel, telLength);
}

export function isValidPMemo(pMemo: string): boolean {
  return pMemo != null && pMemo.trim().length <= memoLength;
}

export function isValidCustNo(custNo: string): boolean {
  return custNo != null && custNo.trim().length <= custNoLength;
}

// パスワード
export function checkPPassword(parent: Parent): boolean {
  const str = parent.pPassword ? parent.pPassword.trim() : '';
  return isValidPassword(str);
}

//  --- 児童一覧
//  兄弟姉妹リスト読み込み
export async function loadFamilies(ctrl: AddParentCtrl, targetDate: Date): Promise<void> {
  await axios
    .get<SFamilyItem[]>('/api/ChildrenSibling/Families', {
      params: {
        datekey: toDateKey(targetDate),
      },
    })
    .then(response => {
      //  登録児童
      ctrl.families = response.data.map(v => {
        const family: SFamily = {
          parent: { ...v.parent },
          childs: [...v.childs],
          check: false,
        };
        return family;
      });
    });
}

export function isValidParent(parent: Parent): boolean {
  const res =
    parent.pCode > 0 &&
    checkPKanaA(parent) &&
    checkPKanaB(parent) &&
    checkPNameA(parent) &&
    checkPNameB(parent) &&
    checkPMail(parent) &&
    checkPPassword(parent);
  return res;
}
